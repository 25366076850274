<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='plus' @click='handleNew()'>新建</a-button>
        <a-divider type='vertical' />
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' size='default' :columns='columns' :data='loadData'>
        <span slot='id' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              <ellipsis :length='10' tooltip>{{ text }}</ellipsis>
            </a>
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge v-if='text=="online"' dot status='processing' :text='"启用"' />
            <a-badge v-if='text=="offline"' dot status='warning' :text='"停用"' />
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a v-if='record.status=="online"' @click='handleEdit(record)'>编辑</a>
              <a v-if='record.status=="online"' @click='handleStatus(record,"offline")'>停用</a>
              <a v-if='record.status=="offline"' @click='handleStatus(record,"online")'>启用</a>
              <a v-if='record.status=="offline"' @click='handleDelete(record)'>删除</a>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryProductionCategories, deleteProductionCategory, putProductionCategory } from '@/api/production'

const columns = [
  {
    title: '#',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '分类名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '分类状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '排序优先级',
    dataIndex: 'priority',
    scopedSlots: { customRender: 'priority' }
  },
  {
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ProductionCategoryList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryProductionCategories(Object.assign(parameter, this.queryParam))
          .then(datum => {
            return datum
          })
      }
    }
  },
  created() {

  },
  methods: {
    handleNew() {
      this.$router.push('/production/category/edit')
    },
    handleEdit(record) {
      this.$router.push({ path: '/production/category/edit', query: { id: record.id } })
    },
    handleStatus(record, status) {
      putProductionCategory(Object.assign({}, record, { status: status })).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      deleteProductionCategory({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    }
  }
}
</script>
